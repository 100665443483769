import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Star from "../components/star"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="W. Murray Butler, DPM" description="Do you have foot pain? Call Dr. Butler, a licensed podiatrist and foot surgeon serving Memphis, Collierville, Germantown, Cordova, and Olive Branch."/>
    <section className="hero">
      <div className="intro">
        <h1>Ready to get back on your&nbsp;feet?</h1>
        <p>
          As a licensed foot and ankle surgeon trained by one of the nation's top podiatry programs, Dr. Butler can help.
        </p>
        <a href="#contact" className="button">Make an Appointment</a>
      </div>
        <Img fluid={data.file.childImageSharp.fluid} alt="Memphis Podiatrist W. Murray Butler"/>
    </section>
    <svg xmlns="http://www.w3.org/2000/svg" className="border" viewBox="0 0 1440 320" preserveAspectRatio="none">
      <path className="blue" d="M0,96L60,117.3C120,139,240,181,360,186.7C480,192,600,160,720,133.3C840,107,960,85,1080,74.7C1200,64,1320,64,1380,64L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
    </svg>
    <div className="blue">
      <section id="values">
        <div id="first">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <circle cx="13" cy="4" r="2" />
              <path d="M4 17l5 1l.75 -1.5" />
              <path d="M15 21l0 -4l-4 -3l1 -6" />
              <path d="M7 12l0 -3l5 -1l3 3l3 1" />
            </svg>
            <h3>Fast relief for foot pain.</h3>
          </div>
          <p>Having served on the medical staff for marathons, triathlons, and sports teams, Dr. Butler
              understands the importance of a quick recovery. And he'll prioritize yours.</p>
        </div>
        <div id="second">
          <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <circle cx="12" cy="12" r="9" />
            <path d="M9 12l2 2l4 -4" />
          </svg>
          <h3>Expertise you can trust.</h3>
          </div>
          <p>Dr. Butler is a leader in his field. He regularly contributes to Memphis Health+Fitness magazine and delivers
            podiatry lectures on a regional and national level.</p>
        </div>
        <div id="third">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path stroke="none" d="M0 0h24v24H0z"/>
              <line x1="3" y1="21" x2="21" y2="21" />
              <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16" />
              <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4" />
              <line x1="10" y1="9" x2="14" y2="9" />
              <line x1="12" y1="7" x2="12" y2="11" />
            </svg>
            <h3>Safe, modern practices.</h3>
          </div>
          <p>When not helping patients, Dr. Butler consults for orthopedic surgical companies, supporting the research and development of new techniques and products.</p>
        </div>
      </section>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" className="border" viewBox="0 0 1440 320" preserveAspectRatio="none">
      <path className="blue" d="M0,256L80,245.3C160,235,320,213,480,218.7C640,224,800,256,960,261.3C1120,267,1280,245,1360,234.7L1440,224L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
    </svg>
    <section id="reviews">
      <div className="rating">
        <Star /><Star /><Star /><Star /><Star />
      </div>
      <h2>Patients rate Dr. Butler among the best podiatrists in Memphis, TN.</h2>
        <blockquote>Dr. Butler is awesome! <strong>He was prompt, very nice and
          easy to talk to.</strong> I have been very frustrated with several other physicians, but he immediately
          identified my problem. <strong>He got me back to running, and I couldn't be happier</strong>!</blockquote>

        <blockquote>Dr. Butler repaired my Achilles tendon and <strong>had me back at work in no time at all</strong>.</blockquote>

        <blockquote>Love Dr. Butler! He is so caring and patient! He never seems
          rushed to get to his next patient. He takes his time to make sure you understand the treatment course he is
          prescribing. <strong>Could not ask for a better doctor!</strong></blockquote>

        <blockquote>I am very pleased with Dr. Butler's work. It's been years
          since I have seen a doctor that was so attentive to my concern. He was straightforward with answers to my
          questions in a sensitive way. Even after he did my surgery, he didn't just leave me with a nurse. He was very
          active in my recovery. He told me every thing that was going to happen...<strong>In my book, Dr. Butler is
            one-of-a-kind and someone I refer my friends to.</strong></blockquote>
        <a className="button" href="https://www.ratemds.com/doctor-ratings/3552797/Dr-W.+Murray-Butler-Collierville-TN.html"
          target="_blank" rel="noreferrer">See more reviews on RateMDs 
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
              <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
            </svg>
          </a>
    </section>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    file(relativePath: { eq: "w-murray-butler-memphis-podiatrist.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`